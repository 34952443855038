<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width title-mailing">
      <div class="page__min-container">
        <div class="text__head">
          <div class="top-border"></div>
          Электронное письмо
        </div>
      </div>
      <div class="page__link-block">
        <a :href="item.urlZip"
           @click.prevent="downloadItemZip(item)"
           class="btn__circle btn__nav-slider btn-download">
          <img src="~assets/img/icons/icon-figma.svg" alt="figma">
        </a>
      </div>
    </div>

    <div class="page__full-width mailing-full">
      <div class="page__min-container m-zero">
        <div class="page__img-wrapper m-zero">
          <img src="~assets/img/mailing-page/mailing-1.png" alt="">
        </div>
      </div>

    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/brand/office-identity">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Офисная айдентика
      </router-link>
      <router-link class="next-page__link" to="/brand/clothes">
        Одежда
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/brand/clothes">
        Одежда
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">

      </router-link>
    </div>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  data(){
    return{
      item: {
        urlZip: '../dowload-file/Brand--Office/mail-page/GDP-mail.zip',
        label: "GDP-mail"
      }
    }
  },
  methods: {
      downloadItemZip ({ urlZip, label }) {
        Axios.get(urlZip, { responseType: 'blob' })
            .then(response => {
              const blob = new Blob([response.data], { type: 'application/zip' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = label
              link.click()
              URL.revokeObjectURL(link.href)
            }).catch(console.error)
    },
    mouseOver(item){
      switch (item){
        case 'black':

          break
        case 'white':

          break
        default:
          break
      }
    },
    mouseLeave(item){
      switch (item){
        case 'black':

          break
        case 'white':

          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/buttons";
.pages-wrap {
  .pages-wrap__top {
    .container__top {
      .pages-wrap__title {
        width: 100%;
      }
    }
  }
}

.page {
  margin-bottom: 192px;
}
.page__link-block{
  margin-left: 20px;
}
.page__contact{
  overflow: inherit;
  position: relative;
}
.page__contact-title-code{
  position: absolute;
  color: #8698A0;
  margin-top: -23px;
  display: none;
  right: -40px;
  font-size: 13px;
}
.page__contact-sub-title{
  .page__contact-title-code{
    right: -55px;
  }
}
.page__contact-title.phone{
  .page__contact-title-code{
    right: -40px;
  }
}
.text__head{
  font-weight: 500;
  letter-spacing: 0.17px;
  margin-bottom: 0;
}
.page__img-wrapper{
  border: 16px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.page__contact-footer{
  .page__contact-title-code{
    right: -52px;
    font-weight: 300;
  }
}
.page__contact-footer:hover,
.page__contact-sub-title:hover,
.page__contact-title:hover{
  .page__contact-title-code{
    display: block;
  }
}
.mailing-full{
  display: flex;
}
.title-mailing{
  display: flex;
}
.page__min-container{
  margin-bottom: 31px;
  width: 100%;
}
.m-zero{
  margin-bottom: 0;
}
@media screen and (max-width: 1456px){
  .page {
    margin-bottom: 204px;
  }
}
@media screen and (max-width: 1216px){
  .page__link-block{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .page__full-width {
    width: 100%;
  }
  .page__contact{
    flex-direction: column;
  }
  .page__contact-half{
    width: 100%;
    max-height: 343px;
  }
}
</style>
